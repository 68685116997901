import {Component, OnDestroy, OnInit} from '@angular/core';
import {MobileMassService} from '@pages/mobile/mobile-start/mobile-mass.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Registrant} from '@/model/registrant.model';
import {MassData} from '@/model/mass-data.model';
import {Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mobile-list',
  templateUrl: './mobile-list.component.html',
  styleUrls: ['./mobile-list.component.scss']
})
export class MobileListComponent implements OnInit, OnDestroy {
  searchString = '';
  massid: string;
  registrantList: Registrant[] = [];
  massData: MassData;
  private registrantsRefreshTimer: any;
  massDataSubscription: Subscription;
  registrantListSubscription: Subscription;
  isLoading: boolean = false;
  sysMessage: string = '';
  filerTagForm: FormGroup;
  massTags = [];
  filterTagsModalRef : NgbModalRef;
  isInit: boolean = true;
  localStorageTags = [];

  constructor(private mobileMassService: MobileMassService,
              private route: ActivatedRoute,
              private router: Router,
              private apiService: ApiService,
              private toastr: ToastrService,
              private formBuilder: FormBuilder,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params.subscribe(
      (params: Params) =>{
        this.massid = params.massid;
        this.massDataSubscription = this.mobileMassService.massDataChanged.subscribe(resData =>{
          this.massData = resData;
        })
        this.getMassRegistrantList();
        this.refreshRegistrants();
      })
    this.filerTagForm = this.formBuilder.group({
      tags: new FormArray([])
    })
    this.localStorageTags = JSON.parse(localStorage.getItem('MassTags'))
  }

  getMassTags(){
    for(let i = 0; i < this.registrantList.length; i++){
      for(let j = 0; j < this.registrantList[i].tags.length; j++){
        if(!this.massTags.find(object => object.tag_name == this.registrantList[i].tags[j])){
          let found = null;
          if(this.localStorageTags){
            found = this.localStorageTags.find(object => object.tag_name == this.registrantList[i].tags[j]);
          }
          if(found){
            this.massTags.push({
              tag_name: this.registrantList[i].tags[j],
              tag_checked: found.tag_checked
            });
          }else{
            this.massTags.push({
              tag_name: this.registrantList[i].tags[j],
              tag_checked: true
            });
          }
        }
      }
    }
    this.addCheckboxes();
  }

  get tagsFormArray() {
    return this.filerTagForm.controls.tags as FormArray;
  }

  private addCheckboxes(){
    this.massTags.forEach(tag => {
      if(tag.tag_checked){
        this.tagsFormArray.push(new FormControl(true));
      }else{
        this.tagsFormArray.push(new FormControl(false));
      }
    });
  }

  getMassRegistrantList(){
    this.registrantListSubscription = this.mobileMassService.fetchMassRegistrants(this.massid).subscribe(resData =>{
      this.registrantList = resData;
      if(this.isInit){
        this.getMassTags();
        this.isInit = false;
      }
      this.isLoading = false;
    }, errorMessage =>{
      this.isLoading = false;
    });
  }

  refreshRegistrants(){
    this.registrantsRefreshTimer = setInterval(() => {
      this.getMassRegistrantList();
    }, 25000);
  }

  ngOnDestroy(): void {
    this.massDataSubscription.unsubscribe();
    this.registrantListSubscription.unsubscribe();
    clearInterval(this.registrantsRefreshTimer);
  }

  clearSearchString(){
    this.searchString = '';
  }

  onClickRegistrantUpdated(registrant: Registrant){
    Swal.fire({
      title: registrant.reg_details,
      html: "<p><b>Remarks: </b><br>" + this.strRemarks(registrant) + "</p>",
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    })
  }

  strRemarks(registrant: Registrant){
    if(registrant.reg_remarks.length > 0){
      return registrant.reg_remarks
    }else{
      return "None"
    }

  }


  onClickRegistrant(registrant: Registrant){
    let tagsOutput = "";
    for(let i = 0; i < registrant.tags.length; i++){
      tagsOutput += "<span class='badge badge-dark mr-1'>" + registrant.tags[i] + "</span>";
    }
    if(registrant.reg_seat !== null){
      Swal.fire({
        title: 'Pre-allocated Seat',
        html: "<p><b>" + registrant.reg_details + "</b><br>has a pre-allocated seat</p><h4>" + registrant.reg_seat + "</h4>" + tagsOutput,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Check-In!'
      }).then((result) => {
        if (result.isConfirmed) {
          let jsonObject = {
            reg_id: registrant.reg_id,
            mass_id: this.massid,
          }
          this.apiService.checkinPreallocatedRegistrant(jsonObject).subscribe(resData =>{
            if(resData.email){
              this.apiService.checkInEmail(registrant.reg_id).subscribe();
            }
            this.isLoading = false;
            this.toastr.success('Successfully checked-in!');
            this.mobileMassService.fetchMassData(this.massid);
            this.getMassRegistrantList();
          }, errorMessage =>{
            Swal.fire({
              title: 'Opps...',
              text: errorMessage.error.message,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'OK'
            }).then((result) => {
              this.mobileMassService.fetchMassData(this.massid);
            });
          })
        }
      })
    }else{
      this.router.navigate([registrant.reg_id], {relativeTo: this.route});
    }

  }

  saveFilters(){
    const selectedTags = this.filerTagForm.value.tags
        .map((checked, i) => {
          if(checked){
            this.massTags[i].tag_checked = true;
            return this.massTags[i];
          }else{
            this.massTags[i].tag_checked = false;
            return this.massTags[i];
          }
        })
        .filter(v => v !== null);
    this.massTags = selectedTags;
    localStorage.setItem('MassTags', JSON.stringify(selectedTags));
    this.closeUpdateTagModal();
    this.toastr.success('Filter Applied');
  }

  open(content) {
    this.filterTagsModalRef = this.modalService.open(content, {
      ariaLabelledBy: 'filterTagsModal',
      scrollable: true
    });
  }

  closeUpdateTagModal(){
    this.filterTagsModalRef.close();
  }

}
