<button type='button' class='btn btn-outline-primary mr-2 mb-1' *ngIf='!showAllocateSeat' (click)='navigateToList()'><i class='fas fa-arrow-circle-left'></i> Go Back</button>
<button type='button' class='btn btn-outline-primary mr-2 mb-1' *ngIf='showAllocateSeat' (click)='navigateToWalkInForm()'><i class='fas fa-arrow-circle-left'></i> Go Back</button>
<h4 class='mt-3'>Walk-In Registration</h4>
<form class='mt-3' autocomplete='off' [formGroup]='walkinRegistrationForm' >
  <div *ngIf='!showAllocateSeat'>
    <div class="pb-3">
      <label for="reg_name">Full Name</label>
      <input type="text" class="form-control form-control-lg" id="reg_name" placeholder="Enter Full Name" formControlName='reg_name'>
    </div>
    <div class="pb-3">
      <label for="reg_age">Age</label>
      <input type="number" class="form-control form-control-lg" id="reg_age" placeholder="Enter Age" formControlName='reg_age'>
    </div>
    <div class="pb-3">
      <label for="reg_phone" id="phoneInputLabel">Phone Number</label>
      <input type="tel" class="form-control form-control-lg" id="reg_phone" value="" placeholder="Enter Phone Number" formControlName='reg_phone'>
    </div>
    <hr>
    <button class='btn btn-primary btn-block' (click)='navigateToAllocateSeat()'>Next</button>
  </div>
</form>
  <div *ngIf='showAllocateSeat'>
    <app-loading-spinner *ngIf='zoneList.length === 0'></app-loading-spinner>
    <p class='mb-2 mt-3 text-muted'>Select Zone:</p>
    <label *ngFor='let zone of zoneList'>
      <input class="card-input-element d-none" type="radio" name="zoneAllocate" [(ngModel)]='zoneAllocate' [value]='zone.zone_id' (click)='allocateSeat(zone.zone_id)'>
      <div class="card card-body bg-light d-flex flex-row justify-content-between align-items-center mb-1" style="height:calc(75px + 2*1rem)">
        <div>
          <h4 class="mt-2 mb-0">{{ zone.zone_colour }}</h4>
          <h6 class="mt-2 mb-0">{{ zone.zone_desc }}</h6>
          <span class="badge badge-info">{{ zone.zone_allocated }} in this zone</span>
        </div>
      </div>
    </label>
  </div>

