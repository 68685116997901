import {Component, OnInit} from '@angular/core';
import {BarcodeFormat} from '@zxing/library';
import {Registrant} from '@/model/registrant.model';
import {Subscription} from 'rxjs';
import {MobileMassService} from '@pages/mobile/mobile-start/mobile-mass.service';
import {ZoneData} from '@/model/zone-data.model';
import {ApiService} from '@services/api.service';
import {ActivatedRoute, Params} from '@angular/router';
import Swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';
import {RegistrantDbSearched} from '@/model/registrant-db-searched.model';

@Component({
  selector: 'app-mobile-qr',
  templateUrl: './mobile-qr.component.html',
  styleUrls: ['./mobile-qr.component.scss']
})
export class MobileQrComponent implements OnInit {
  cameraScannerEnabled: boolean = true;
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  registrantList: Registrant[] = [];
  zoneList: ZoneData[] = [];
  registrantListSubscription: Subscription;
  massid: string;
  isLoading: boolean = false;
  searchedRegistrantData: RegistrantDbSearched;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.QR_CODE,
  ];

  hasDevices: boolean;
  hasPermission: boolean;

  registrantResultDetails: string;
  registrantResultQrString: string;
  zoneSeatResultQrString: string;
  zoneSeatResultDetails: string;

  constructor(private mobileMassService: MobileMassService,
              private apiService: ApiService,
              private route: ActivatedRoute,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.route.parent.params.subscribe((params: Params) =>{
      this.massid = params.massid;
    })
    this.mobileMassService.registrantsChanged.subscribe(resData =>{
      this.registrantList = resData;
    });
    this.apiService.fetchZoneData(this.massid).subscribe(resData =>{
      this.zoneList = resData;
    });
  }

  clearResult(): void {
    this.registrantResultQrString = null;
    this.registrantResultDetails = null;
    this.zoneSeatResultQrString = null;
    this.zoneSeatResultDetails = null;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {
    if(!this.registrantResultQrString){
      this.registrantResultDetails = null;
      this.searchedRegistrantData = null;
      let foundRegistrant: Registrant;
      foundRegistrant = this.registrantList.find(element => element.reg_id == resultString);
      if(foundRegistrant){
        this.registrantResultQrString = resultString;
        this.registrantResultDetails = foundRegistrant.reg_details;
      }else{
        if(!resultString.includes("COV")){
          this.apiService.searchRegistrantData(resultString).subscribe(resData =>{
            this.searchedRegistrantData = resData;
          }, errorMessage =>{
            this.registrantResultDetails = errorMessage.error.message;
          })
        }else{
          this.registrantResultDetails = 'Scan Registrant QR first.';
        }
      }
    }else if(!this.zoneSeatResultQrString){
      if(resultString.includes('COV')){
        this.zoneSeatResultQrString = resultString;
        let zoneSeatArray = resultString.split("-");
        let selectedZone: ZoneData = this.zoneList.find(element => element.zone_id === zoneSeatArray[1]);
        this.zoneSeatResultDetails = selectedZone.zone_colour + ' ' + zoneSeatArray[2];
        this.onSubmit(this.registrantResultQrString, zoneSeatArray[1], +zoneSeatArray[2], selectedZone.zone_colour, 'N');
      }else{
        this.zoneSeatResultDetails = resultString + ' is not a valid Zone/Seat QR';
      }

    }

  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.currentDevice = device || null;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onSubmit(reg_id: string, zone_id: string, seat: number, zone_colour: string, override: string){
    this.isLoading = true;
    let jsonObject = {
      zone_id: zone_id,
      reg_checkinseat: seat,
      reg_id: reg_id,
      mass_id: this.massid,
      override: override
    }
    this.apiService.checkinRegistrant(jsonObject).subscribe(resData =>{
      this.isLoading = false;
      if(resData.email){
        this.apiService.checkInEmail(reg_id).subscribe();
      }
      this.toastr.success('Successfully checked-in!\n' + this.registrantResultDetails + '->' + zone_colour + ' ' + seat);
      this.mobileMassService.fetchMassData(this.massid);
      this.mobileMassService.fetchMassRegistrants(this.massid).subscribe();
      this.clearResult();
    }, errorMessage =>{
      this.isLoading = false;
      if(errorMessage.error.error == '406'){
        Swal.fire({
          title: 'Opps...',
          html: errorMessage.error.message + '<br>Do you want to continue allocating to the same seat?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result) => {
          if(result.isConfirmed){
            this.onSubmit(reg_id, zone_id, seat, zone_colour,'Y');
          }else{
            this.zoneSeatResultDetails = null;
            this.zoneSeatResultQrString = null;
          }
        });
      }else {
        Swal.fire({
          title: 'Opps...',
          text: errorMessage.error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {

        });
      }
    })
  }
}
