import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import Swal from 'sweetalert2';
import {MobileMassService} from '@pages/mobile/mobile-start/mobile-mass.service';
import {Subscription} from 'rxjs';
import {MassData} from '@/model/mass-data.model';
import {AppService} from '@services/app.service';
import {User} from '@/model/user.model';

@Component({
  selector: 'app-mobile-start',
  templateUrl: './mobile-start.component.html',
  styleUrls: ['./mobile-start.component.scss']
})
export class MobileStartComponent implements OnInit, OnDestroy {
  goBack: boolean = false;
  massid: string;
  private massRefreshTimer: any;
  private massDataSubscription: Subscription;
  massData: MassData;
  user: User;

  constructor(private router: Router, private route: ActivatedRoute, private mobileMassService: MobileMassService, private appService: AppService) { }

  ngOnInit(): void {
    this.user = this.appService.user.getValue();
    this.route.params.subscribe(
      (params: Params) => {
        this.massid = params.massid;
      }
    )
    this.goBack = this.router.url.includes('qr') || this.router.url.includes('walk-in');
    this.router.events.subscribe(event => {
      this.goBack = this.router.url.includes('qr') || this.router.url.includes('walk-in');
      // filter `NavigationEnd` events
      // if (event instanceof NavigationEnd) {
      //   // get current route without leading slash `/`
      //   const eventUrl = /(?<=\/).+/.exec(event.urlAfterRedirects);
      //   const currentRoute = (eventUrl || []).join('');
      //   if(currentRoute.includes('qr') || currentRoute.includes('walk-in')){
      //     this.goBack = true;
      //   }else{
      //     this.goBack = false;
      //   }
      // }
    });
    this.mobileMassService.fetchMassData(this.massid).subscribe();
    this.massDataSubscription = this.mobileMassService.massDataChanged.subscribe(resData =>{
      this.massData = resData;
    })
    // this.refreshMasses();
  }

  navigateBack(){
    this.router.navigate(['../'+this.massid], {relativeTo: this.route});
  }

  navigateMain(){
    Swal.fire({
      title: 'Are you sure?',
      text: "You will be navigated back to the main page.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['../'], {relativeTo: this.route});
      }
    })
  }

  refreshMasses(){
    this.massRefreshTimer = setInterval(() => {
      this.mobileMassService.fetchMassData(this.massid).subscribe();
    }, 7000);
  }

  ngOnDestroy(){
    this.massDataSubscription.unsubscribe();
    clearInterval(this.massRefreshTimer);
  }

  seatsLeft(maxcap: number, checkedin: number, bufferseats: number){
    if(checkedin < maxcap){
      return maxcap - checkedin;
    }else{
      return (maxcap + bufferseats) - checkedin;
    }

  }

  btnStyle(maxcap: number, checkedin: number, bufferseats: number){
    if(checkedin >= (maxcap + bufferseats)){
      return 'danger';
    }else if(bufferseats > 0 && checkedin >= maxcap && checkedin < (maxcap + bufferseats)){
      return 'info';
    }else{
      return 'outline-dark';
    }
  }

  seatsLeftText(maxcap: number, checkedin: number, bufferseats: number){
    if(checkedin < maxcap){
      return 'Seats Left';
    }else{
      return 'Buffer Seats';
    }

  }

}
