<button type='button' class='btn btn-outline-primary mr-2 mb-1' [routerLink]="['../']"><i class='fas fa-arrow-circle-left'></i> Go Back</button>
<div [hidden]="!hasDevices">
  <div class='form-group'>
    <label for='qr_deviceList'>Select Device</label>
    <select class='custom-select' id='qr_deviceList' (change)="onDeviceSelectChange($event.target.value)">
      <option value="" [selected]="!currentDevice">No Device Selected</option>
      <option *ngFor="let device of availableDevices" [value]="device.deviceId"
              [selected]="currentDevice && device.deviceId === currentDevice.deviceId">{{device.label}}</option>
    </select>
  </div>
  <div class="alert alert-warning" role="alert" *ngIf='!registrantResultQrString'>
    <strong>Detecting REGISTRANT QR Code...</strong> Place QR code within the camera frame.
  </div>
  <div class="alert alert-{{ registrantResultQrString ? 'success' : 'danger' }}" role="alert" *ngIf='registrantResultDetails'>
    <strong *ngIf='registrantResultQrString'>Registration Valid! </strong>
    <strong *ngIf='!registrantResultQrString'>This QR code is NOT valid!</strong> {{ registrantResultDetails }}
  </div>
  <div class="alert alert-info" role="alert" *ngIf='searchedRegistrantData && searchedRegistrantData.mass_id !== massid'>
    {{ searchedRegistrantData.reg_name }} did not register for this Mass, instead he/she registered for {{ searchedRegistrantData.mass_lang }}
    {{ searchedRegistrantData.mass_datetime }}.<br>Kindly prompt him/her on this.<br>
    <button class='btn btn-primary mt-1'>Register as Walk-In</button>
  </div>
  <div class="alert alert-info" role="alert" *ngIf='searchedRegistrantData && searchedRegistrantData.mass_id === massid && searchedRegistrantData.reg_checkindatetime !== "-"'>
    {{ searchedRegistrantData.reg_name }} already checked-in at {{ searchedRegistrantData.reg_checkindatetime }}.<br>
    He/She is allocated to seat {{ searchedRegistrantData.zone_colour }} {{ searchedRegistrantData.reg_checkinseat }}.
  </div>
  <div class="alert alert-warning" role="alert" *ngIf='!zoneSeatResultQrString && registrantResultQrString'>
    <strong>Detecting Seat QR Code...</strong> Place QR code within the camera frame.
  </div>
  <div class="alert alert-{{ zoneSeatResultQrString ? 'success' : 'danger' }}" role="alert" *ngIf='zoneSeatResultDetails'>
    <strong *ngIf='zoneSeatResultQrString'>Zone/Seat: </strong>
    <strong *ngIf='!zoneSeatResultQrString'>This QR code is NOT valid!</strong> {{ zoneSeatResultDetails }}
  </div>
  <app-loading-spinner *ngIf='isLoading'></app-loading-spinner>
  <zxing-scanner [enable]="cameraScannerEnabled" [(device)]="currentDevice" (scanSuccess)="onCodeResult($event)"
                 [formats]="formatsEnabled"  (permissionResponse)="onHasPermission($event)"
                 (camerasFound)="onCamerasFound($event)" ></zxing-scanner>
</div>

<div *ngIf="hasPermission === undefined">
  <h2>Waiting for permissions.</h2>
  <blockquote>
    If your device does not has cameras, no permissions will be asked.
  </blockquote>
</div>
<div *ngIf="hasPermission === false">
  <h2>You denied the camera permission, we can't scan anything without it. 😪</h2>
</div>
<div *ngIf="hasDevices === undefined">
  <h2>Couldn't check for devices.</h2>

  <blockquote>
    This may be caused by some security error.
  </blockquote>
</div>
<div *ngIf="hasDevices === false">

  <h2>No devices were found.</h2>

  <blockquote>
    I believe your device has no media devices attached to.
  </blockquote>
</div>
<button class='btn btn-danger' *ngIf='hasDevices' (click)='clearResult()'>Start Over</button>
