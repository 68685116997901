<li class="nav-item dropdown user-menu">
    <a
        class="nav-link dropdown-toggle"
        data-toggle="dropdown"
        (click)="toggleDropdownMenu()"
    >
        <img
            [src]="user.picture || 'assets/img/default-profile.png'"
            class="user-image img-circle elevation-2"
            alt="User Image"
        />
    </a>
    <ul
        #dropdownMenu
        class="dropdown-menu dropdown-menu-lg dropdown-menu-right"
    >
        <!-- User image -->
        <li class="user-header bg-primary">
            <img
                [src]="user.picture || 'assets/img/default-profile.png'"
                class="img-circle elevation-2"
                alt="User Image"
            />

            <p>
                <span>{{ user.email }}</span>
                <small>
                    <span>Member since </span>
                    <span>{{ formatDate(user.createdAt) }}</span>
                </small>
            </p>
        </li>
        <!-- Menu Body -->
        <li class="user-body">
            <div class="row">
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Followers</a>
                </div>
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Sales</a>
                </div>
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Friends</a>
                </div>
            </div>
            <!-- /.row -->
        </li>
        <!-- Menu Footer-->
        <li class="user-footer">
            <a [routerLink]="['/profile']" class="btn btn-default btn-flat">
                Profile
            </a>
            <a (click)="logout()" class="btn btn-default btn-flat float-right"
                >Sign out</a
            >
        </li>
    </ul>
</li>
