<div class='card card-outline card-primary'>
  <div class='card-header text-center'>
    <img class='img-fluid mb-2'
         [src]="'assets/img/COV_Logo_new.png'"
         style='width: 200px'
    /><br>
    <h1><b>MRS</b>check-in</h1>
    <p class='mt-0'>Mass Check-In Portal</p>
  </div>
  <div class='card-body'>
    <p class='login-box-msg'>Sign in with your credentials</p>

    <form [formGroup]='loginForm' (ngSubmit)='loginByAuth()'>
      <div class='input-group mb-3'>
        <input
          formControlName='email'
          type='email'
          class='form-control'
          placeholder='Email'
        />
        <div class='input-group-append'>
          <div class='input-group-text'>
            <span class='fas fa-envelope'></span>
          </div>
        </div>
      </div>
      <div class='input-group mb-3'>
        <input
          formControlName='password'
          type='password'
          class='form-control'
          placeholder='Password'
        />
        <div class='input-group-append'>
          <div class='input-group-text'>
            <span class='fas fa-lock'></span>
          </div>
        </div>
      </div>
      <div class='row'>
        <div class='col-7'>
          <p class='text-muted'>
            Version: 2.1
          </p>
        </div>
        <!-- /.col -->
        <div class='col-5'>
          <app-button
            [type]="'submit'"
            [block]='true'
            [loading]='isAuthLoading'
          >
            Sign In
          </app-button>
        </div>
        <!-- /.col -->
      </div>
    </form>
  </div>
  <!-- /.login-card-body -->
</div>
