import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTags',
  pure: false
})
export class FilterTagsPipe implements PipeTransform {

  transform(value: any, filterArray: any): unknown {
    if (value.length === 0 || filterArray === []) {
      return value;
    }
    const resultArray = [];
    for (const item of value) {
      if(item.tags.length !== 0){
        for (const tag of item.tags){
          let found = filterArray.find(object => object.tag_name == tag)
          if(found && found.tag_checked){
            resultArray.push(item);
          }
        }
      }else{
        resultArray.push(item);
      }

    }
    return resultArray;
  }

}
