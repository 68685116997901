import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {User} from '@/model/user.model';
import {AppService} from '@services/app.service';
import {Mass} from '@/model/mass.model';
import {ApiService} from '@services/api.service';
import {map, tap} from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mobile-mass',
  templateUrl: './mobile-mass.component.html',
  styleUrls: ['./mobile-mass.component.scss']
})
export class MobileMassComponent implements OnInit, OnDestroy {
  private userSub: Subscription;
  isLoading: boolean = false;
  noMassAvailable: boolean = false;
  user: User;
  massList: Mass[] = []

  constructor(private appService: AppService, private apiService: ApiService) { }

  ngOnInit() {
    this.isLoading = true;
    this.userSub = this.appService.user.subscribe(user => {
      this.user = user;
    });
    this.apiService.fetchAllMasses().pipe(
      map(masses => {
        return masses.map(mass => {
          return {...mass};
        });
      }),
      tap(masses => {
        this.massList = masses;
      })
    ).subscribe(resData =>{
      this.isLoading = false;
      if(this.massList.length === 0){
        this.noMassAvailable = true;
      }
    }, errorMessage =>{
      this.isLoading = false;
      Swal.fire({
        title: 'Opps...',
        html: errorMessage.error.message,
        imageUrl: 'https://img.stickers.cloud/packs/5fc74a2c-3e4d-4eb0-81b9-41681a5c0941/webp/e041cb6f-39a9-46ed-8747-e4338731a802.webp',
        imageHeight: 200,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }

  logout() {
    this.appService.logout();
  }

}
