<button type='button' class='btn btn-outline-primary mr-2 mb-1' (click)='navigateToList()'><i class='fas fa-arrow-circle-left'></i> Go Back</button>
<h4 class='mt-3'>{{ selectedRegistrant.reg_details }}</h4>
<blockquote>
  <span class="badge badge-warning" *ngIf="selectedRegistrant.cancelled === '1'">Cancelled Previously</span>
  <p><strong>Remarks by registrant</strong></p>
  {{ !selectedRegistrant.reg_remarks ? 'None' : selectedRegistrant.reg_remarks }}
</blockquote>
<app-loading-news *ngIf='isLoading'></app-loading-news>
<p class='mb-2 mt-3 text-muted'>Select Zone:</p>
<label *ngFor='let zone of zoneList'>
  <input class="card-input-element d-none" type="radio" name="zoneAllocate" [(ngModel)]='zoneAllocate' [value]='zone.zone_id' (click)='allocateSeat(zone.zone_id)'>
  <div class="card card-body bg-light d-flex flex-row justify-content-between align-items-center mb-1" style="height:calc(75px + 2*1rem)">
    <div>
      <h4 class="mt-2 mb-0">{{ zone.zone_colour }}</h4>
      <h6 class="mt-2 mb-0">{{ zone.zone_desc }}</h6>
      <span class="badge badge-info">{{ zone.zone_allocated }} in this zone</span>
    </div>
  </div>
</label>
