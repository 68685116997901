<div class='container'>
  <div class='row justify-content-md-center'>
    <div class='col-12 col-lg-8'>
      <div class='card'>
        <div class='card-body'>
          <h5 class='mb-0'>Hello, {{ user.name }}</h5>
<!--          <p class='mb-0 text-muted'>Thank you for serving today 😘</p>-->
          <br>
          <div class='alert alert-info'>
            <h4>Thank You!</h4>
            <p>Effective from 1st April 2022, seat allocation contact tracing is no longer required in our parish. We take this opportunity to thank you for volunteering thus far.
            This system has been modified and most of the features has been disabled. The registrant list is still accessible.</p>
            <p>The Church is still calling for more volunteers for other ministries.<br><a href='https://visitationseremban.org/index.php/newsroom/announcements/773-calling-for-volunteers-2' role='button' class='btn btn-dark' target='_blank'>Read more</a></p>
          </div>
          <p class='mb-0 text-muted'>Menu</p>
          <button type='button' class='btn btn-outline-primary mr-2' (click)='logout()'><i
            class='fas fa-sign-out-alt'></i> Sign-Out
          </button>
          <p class='mb-0 mt-3 text-muted'>Masses available for check-in:</p>
          <div class='row'>
            <app-loading-news *ngIf='isLoading'></app-loading-news>
            <div class='col-12 col-lg-12 mb-2' *ngIf='noMassAvailable'>
              <div style='text-align: center;'>
                <img [src]="'assets/img/noresfound.gif'" height='80' class='mb-2' alt='' loading='lazy'>
                <h5 class='mb-0 text-muted'>There are no Mass available for check-in.</h5>
                <p class='mb-0 text-muted'>Check back again later.</p>
              </div>
            </div>
            <div class='col-12 col-lg-12 mb-2' *ngFor='let mass of massList'>
              <button type='button' class='btn btn-outline-success btn-lg btn-block' style='text-align: left' [routerLink]="[mass.mass_id]">
                <p class='mb-0' style='font-size: smaller'>{{ mass.mass_desc }}</p>
                {{ mass.mass_lang }}<br>
                <strong>{{mass.mass_datetime | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A'}}</strong>

              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
