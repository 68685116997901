import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesDropdownMenuComponent} from '@modules/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
import {NotificationsDropdownMenuComponent} from '@modules/main/header/notifications-dropdown-menu/notifications-dropdown-menu.component';
import {AppButtonComponent} from './components/app-button/app-button.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';

import {registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserDropdownMenuComponent} from '@modules/main/header/user-dropdown-menu/user-dropdown-menu.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageDropdownComponent} from '@modules/main/header/language-dropdown/language-dropdown.component';
import {PrivacyPolicyComponent} from './modules/privacy-policy/privacy-policy.component';
import {MobileComponent} from '@pages/mobile/mobile.component';
import {MobileMassComponent} from '@pages/mobile/mobile-mass/mobile-mass.component';
import {LoadingSpinnerComponent} from '@components/loading-spinner/loading-spinner.component';
import {ShortenPipe} from '@components/shorten.pipe';
import {FilterPipe} from '@components/filter.pipe';
import {MobileStartComponent} from '@pages/mobile/mobile-start/mobile-start.component';
import {MobileMassService} from '@pages/mobile/mobile-start/mobile-mass.service';
import {MobileListComponent} from '@pages/mobile/mobile-start/mobile-list/mobile-list.component';
import {MobileQrComponent} from '@pages/mobile/mobile-start/mobile-qr/mobile-qr.component';
import {ApiInterceptorService} from '@services/api-interceptor.service';
import {MobileWalkinComponent} from '@pages/mobile/mobile-start/mobile-walkin/mobile-walkin.component';
import {DateTimeFormatPipe} from '@components/dateTimeFormatFilter.pipe';
import { MobileCheckinComponent } from './pages/mobile/mobile-start/mobile-checkin/mobile-checkin.component';
import { MobileFullListComponent } from './pages/mobile/mobile-start/mobile-full-list/mobile-full-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DesktopQrComponent } from './pages/desktop/desktop-qr/desktop-qr.component';
import {ReversePipe} from '@components/reverse.pipe';
import {FilterTagsPipe} from '@components/filterTags.pipe';
import { LoadingNewsComponent } from './components/loading-news/loading-news.component';

registerLocaleData(localeEn, 'en-EN');

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    BlankComponent,
    ProfileComponent,
    RegisterComponent,
    DashboardComponent,
    MessagesDropdownMenuComponent,
    NotificationsDropdownMenuComponent,
    AppButtonComponent,
    UserDropdownMenuComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    LanguageDropdownComponent,
    PrivacyPolicyComponent,
    MobileComponent,
    MobileMassComponent,
    LoadingSpinnerComponent,
    ShortenPipe,
    FilterPipe,
    MobileStartComponent,
    MobileListComponent,
    MobileQrComponent,
    MobileWalkinComponent,
    DateTimeFormatPipe,
    MobileCheckinComponent,
    MobileFullListComponent,
    DesktopQrComponent,
    ReversePipe,
    FilterTagsPipe,
    LoadingNewsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    FormsModule,
    ZXingScannerModule,
    NgxScrollTopModule,
    NgbModule,
    NgxHideOnScrollModule
  ],
  providers: [MobileMassService, {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
