import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {MobileMassService} from '@pages/mobile/mobile-start/mobile-mass.service';
import {Registrant} from '@/model/registrant.model';

@Injectable({providedIn: 'root'})
export class RegistrantListResolver implements Resolve<Registrant[]>{
  constructor(private mobileMassService: MobileMassService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Registrant[]> | Promise<Registrant[]> | Registrant[] {
    if (this.mobileMassService.registrantList.length === 0){
      return this.mobileMassService.fetchMassRegistrants(route.parent.params.massid);
    } else{
      return this.mobileMassService.registrantsChanged.getValue();
    }
  }
}
