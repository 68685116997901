<ngx-scrolltop
  [backgroundColor]="'#212121'"
  [symbolColor]="'#fafafa'"
  [size]="40"
  [mode]="'classic'"
  position="right"
  theme="gray">
</ngx-scrolltop>
<!--<button type='button' class='btn btn-outline-primary mr-2 mb-1' [routerLink]="['qr']"><i class='fas fa-qrcode'></i> Scan QR (Camera)</button>-->
<!--<button type='button' class='btn btn-outline-primary mr-2 mb-1 d-none d-sm-inline-block' [routerLink]="['desktop-qr']"><i class='fas fa-qrcode'></i> Desktop QR Scan</button>-->
<!--<button type='button' class='btn btn-outline-primary mr-2 mb-1' [routerLink]="['full']"><i class='far fa-list-alt'></i> Name List</button>-->
<div class='alert alert-info'>
  Total pre-registered: {{ registrantList.length }}
</div>
<div *ngIf='massData' class='mt-2'>
  <div class='alert alert-danger' *ngIf='+massData.mass_checkedin >= (+massData.mass_maxcap + +massData.mass_checkinbuffer)'>
    Maximum capacity reached. Check-In DISABLED.
  </div>
  <div class='alert alert-info' *ngIf='+massData.mass_checkinbuffer > 0 && +massData.mass_checkedin >= +massData.mass_maxcap && +massData.mass_checkedin < (+massData.mass_maxcap + +massData.mass_checkinbuffer)'>
    Maximum capacity reached. Buffer Seats for migrants is currently enabled.
  </div>
  <div class='alert alert-danger' *ngIf='massData.mass_walkin === massData.mass_lastmincap'>
    Walk-in capacity reached. Walk-in registration DISABLED.
  </div>
</div>
<p class='mb-0 mt-2 text-muted'>Search</p>
<div class="input-group mb-1">
  <input type="text" class="form-control form-control-lg" placeholder="Code or Name" aria-describedby="button-addon2" [(ngModel)]="searchString">
  <div class="input-group-append">
    <button class="btn btn-outline-danger" type="button" id="button-addon2" (click)='clearSearchString()'><i class="fas fa-eraser"></i></button>
  </div>
</div>
<button type="button" class="btn btn-light btn-block mb-2" (click)='open(content)'><i class="fas fa-filter"></i> Filter</button>
<!--<input class='form-control form-control-lg' id='tempSearch' type='text' placeholder='Name or Code' [(ngModel)]="filteredStatus">-->

<div class='col-12 col-lg-12 mb-2' *ngIf='registrantList.length === 0 && !isLoading'>
  <div style='text-align: center;'>
    <img [src]="'assets/img/noresfound.gif'" height='80' class='mb-2' alt='' loading='lazy'>
    <h5 class='mb-0 text-muted'>There are no registrants to display.</h5>
    <p class='mb-0 text-muted'>Check back again later.</p>
  </div>
</div>
<app-loading-news *ngIf='isLoading'></app-loading-news>
<ul class='list-group' *ngIf='massData'>
  <li class='list-group-item list-group-item-action' *ngFor="let registrant of registrantList | filter:searchString: 'reg_details' | filterTags:massTags" (click)='onClickRegistrantUpdated(registrant)' style='cursor: pointer'>
    {{ registrant.reg_details }}
    <span class="badge badge-warning fa-pull-right mr-1" *ngIf="registrant.cancelled === '1'">Cancelled</span>
    <span class="badge badge-dark fa-pull-right mr-1" *ngFor="let tag of registrant.tags">{{ tag }}</span>
  </li>
</ul>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modalChangePassword">Filters</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeUpdateTagModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]='filerTagForm' autocomplete='off'>
      <div class='form-group' *ngFor="let tag of massTags; let i = index">
        <label formArrayName="tags">
          <input type="checkbox" [formControlName]="i">
          {{tag.tag_name}}
        </label>
      </div>

      <div class='alert alert-default-info' *ngIf='!filerTagForm.valid'>
        Form is not valid. Please check the form before saving.
      </div>
      <div class='alert alert-default-info' *ngIf='filerTagForm.valid && !filerTagForm.dirty'>
        There are no changes made yet.
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <app-button
      [type]="'button'"
      [block]='false'
      [color]="'primary'"
      [disabled]='!filerTagForm.valid || !filerTagForm.dirty'
    (click)='saveFilters()'>
      Save
    </app-button>
    <!--    <button type="button" class="btn btn-primary" (click)="onUpdateTag()" [disabled]='!updateTagForm.valid || !updateTagForm.dirty'>{{ editMode ? 'Update Tag' : 'Create Tag' }}</button>-->
    <button type="button" class="btn btn-outline-dark" (click)="closeUpdateTagModal()">Close</button>
  </div>
</ng-template>


