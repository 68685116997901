<button type='button' class='btn btn-outline-primary mr-2 mb-1' [routerLink]="['../']"><i class='fas fa-arrow-circle-left'></i> Go Back</button>
<div class='row mt-4'>
  <div class='col-12 col-lg-4' style='text-align: center'>
    <img [src]="'assets/img/desktop-qr-scan.png'" height='200px'>
  </div>
  <div class='col-12 col-lg-8' *ngIf='showInstructions'>
    <h3>Step 1:</h3>
    <p>Connect the physical QR scanner to your machine via a USB scanner.</p>
    <h3>Step 2:</h3>
    <p>Registrant present and scan their registration QR code on the physical QR scanner. You will hear a "beep" sound when a QR code is detected.
      The system will verify whether it is a valid registration and will show you the registrant's details.</p>
    <h3>Step 3:</h3>
    <p>Scan the QR code behind the Zone/Seat card. You will hear a "beep" sound when a QR code is detected.
      If it is a valid QR code, the registrant will be automatically checked-in. You will see a "green check" alert if everything was successful.
    </p>
    <button class='btn btn-primary fa-pull-right' (click)='skipInstructions()'>Let's Start</button>
  </div>
  <div class='col-12 col-lg-8' *ngIf='!showInstructions'>
    <div class="alert alert-warning" role="alert" *ngIf='!registrantResultQrString'>
      <strong>Detecting REGISTRANT QR code...</strong> Place QR code within the scan area of the QR scanner.
    </div>
    <div class="alert alert-{{ registrantResultQrString ? 'success' : 'danger' }}" role="alert" *ngIf='registrantResultDetails'>
      <strong *ngIf='registrantResultQrString'>Registration Valid! </strong>
      <strong *ngIf='!registrantResultQrString'>Not a valid Registration QR code!</strong> {{ registrantResultDetails }}
    </div>
    <div class="alert alert-info" role="alert" *ngIf='searchedRegistrantData && searchedRegistrantData.mass_id !== massid'>
      {{ searchedRegistrantData.reg_name }} did not register for this Mass, instead he/she registered for {{ searchedRegistrantData.mass_lang }}
      {{ searchedRegistrantData.mass_datetime }}.<br>Kindly prompt him/her on this.<br>
      <button class='btn btn-primary mt-1'>Register as Walk-In</button>
    </div>
    <div class="alert alert-info" role="alert" *ngIf='searchedRegistrantData && searchedRegistrantData.mass_id === massid && searchedRegistrantData.reg_checkindatetime !== "-"'>
      {{ searchedRegistrantData.reg_name }} already checked-in at {{ searchedRegistrantData.reg_checkindatetime }}.<br>
      He/She is allocated to seat {{ searchedRegistrantData.zone_colour }} {{ searchedRegistrantData.reg_checkinseat }}.
    </div>
    <div class="alert alert-warning" role="alert" *ngIf='!zoneSeatResultQrString && registrantResultQrString'>
      <strong>Detecting Seat QR code...</strong> Place QR code within the scan area of the QR scanner.
    </div>
    <div class="alert alert-{{ zoneSeatResultQrString ? 'success' : 'danger' }}" role="alert" *ngIf='zoneSeatResultDetails'>
      <strong *ngIf='zoneSeatResultQrString'>Zone/Seat: </strong>
      <strong *ngIf='!zoneSeatResultQrString'>Not a valid Zone/Seat QR code!</strong> {{ zoneSeatResultDetails }}
    </div>
    <app-loading-spinner *ngIf='isLoading'></app-loading-spinner>
    <button class='btn btn-danger' *ngIf='!showInstructions' (click)='clearResult()'>Start Over</button>
  </div>
  <div class='col-12 col-lg-12 mt-3' *ngIf='!showInstructions'>
    <h3>Logs (Latest on top)</h3>
    <div class='overflow-auto' style='height: 200px; background-color: lightgrey'>
      <p *ngFor='let log of scanLogs | reverse'>{{ log }}</p>
    </div>
  </div>
  <div class='col-12 col-lg-12 mt-3' *ngIf='!showInstructions'>
    <h3>Trobleshooting</h3>
    <h6>QR scanner "beep" when a QR code is scanned but nothing is displayed, not even the logs.</h6>
    <p>- Try refreshing this page and try again.<br>- Try unplugging and replugging the scanner.</p>
    <h6>QR scanner does not "beep" when a QR code is presented.</h6>
    <p>- Present the QR code further away or at different angles.<br>- Make sure when registrant scan their QR code, their device's screen brightness is not too dark.
    <br>- Make sure the QR scanner is active by simply waving your hand on top of the scanner. The scanner's light should light up.</p>
    <h6>QR scanner is not sensitive.</h6>
    <p>- This may be due to some factors like environment light, device phone screen brightness, etc.</p>
    <h6>QR scanner does not turn on even when plugged in.</h6>
    <p>- Try another USB port on your machine and refresh this page.</p>
  </div>
</div>

