import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Registrant} from '@/model/registrant.model';
import {ApiService} from '@services/api.service';
import {map, tap} from 'rxjs/operators';
import {MassData} from '@/model/mass-data.model';
import moment from 'moment';

@Injectable()
export class MobileMassService{
  registrantList: Registrant[] = [];
  massData: MassData;
  massDataChanged =  new BehaviorSubject<MassData>(null);
  registrantsChanged = new BehaviorSubject<Registrant[]>([]);
  showDesktopScanInstructions = new BehaviorSubject<boolean>(true);
  desktopScanLogs = new BehaviorSubject<string[]>([]);

  constructor(private apiService: ApiService) {
  }

  fetchMassRegistrants(mass_id: string){
    return this.apiService.fetchMassRegistrants(mass_id).pipe(
      map(registrants => {
        return registrants.map(registrant => {
          return {...registrant};
        });
      }),
      tap(registrants => {
        this.registrantList = registrants;
        this.registrantsChanged.next(this.registrantList.slice());
      })
    );
  }

  fetchMassData(mass_id: string){
    return this.apiService.fetchMassData(mass_id).pipe(
      tap(massData =>{
        this.massData = massData;
        this.massDataChanged.next(this.massData);
      })
    );
  }

  getRegistrant(reg_id: string): Registrant{
    return this.registrantList.find(element => element.reg_id === reg_id);
  }

  setShowDesktopScanInstructions(value: boolean){
    this.showDesktopScanInstructions.next(value);
  }

  addDesktopScanLogs(value: string){
    this.desktopScanLogs.next(this.desktopScanLogs.getValue().concat([moment().format('h:mm:ss a') + ' ' + value]));
  }
}
