<ngx-scrolltop
  [backgroundColor]="'#212121'"
  [symbolColor]="'#fafafa'"
  [size]="40"
  [mode]="'classic'"
  position="right"
  theme="gray">
</ngx-scrolltop>
<button type='button' class='btn btn-outline-primary mr-2 mb-1' [routerLink]="['../']"><i class='fas fa-arrow-circle-left'></i> Go Back</button>
<p class='mb-0 mt-2 text-muted'>Search</p>
<div class="input-group mb-3">
  <input type="text" class="form-control form-control-lg" placeholder="{{ searchBy === 'reg_details' ? 'Code or Name' : 'Zone or Seat'}}" aria-describedby="button-addon2" [(ngModel)]="searchString">
  <div class="input-group-append">
    <button class="btn btn-outline-danger" type="button" id="button-addon2" (click)='clearSearchString()'><i class="fas fa-eraser"></i></button>
  </div>
</div>
<p class='mb-0 mt-2 text-muted' *ngIf='nameList.length > 0 && !isLoading'>Search by</p>
<div class="form-check form-check-inline mb-3 mr-4" *ngIf='nameList.length > 0 && !isLoading'>
  <input class="form-check-input" type="radio" name="searchBy" id="inlineRadio1" [(ngModel)]='searchBy' value="reg_details">
  <label class="form-check-label" for="inlineRadio1">Code/Name</label>
</div>
<div class="form-check form-check-inline mb-3" *ngIf='nameList.length > 0 && !isLoading'>
  <input class="form-check-input" type="radio" name="searchBy" id="inlineRadio2" [(ngModel)]='searchBy' value="reg_allocatedseat">
  <label class="form-check-label" for="inlineRadio2">Zone/Seat</label>
</div>
<app-loading-news *ngIf='isLoading'></app-loading-news>
<div class='col-12 col-lg-12 mb-2' *ngIf='nameList.length === 0 && !isLoading'>
  <div style='text-align: center;'>
    <img [src]="'assets/img/noresfound.gif'" height='80' class='mb-2' alt='' loading='lazy'>
    <h5 class='mb-0 text-muted'>There nothing to display.</h5>
    <p class='mb-0 text-muted'>Check back again later.</p>
  </div>
</div>
<div class='table-responsive' *ngIf='nameList.length > 0 && !isLoading'>
  <table class="table table-bordered table-hover">
    <thead>
    <tr>
      <th scope="col">Code/Name</th>
      <th scope="col">Zone/Seat</th>
      <th scope="col">Actions</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor='let detail of nameList | filter:searchString: searchBy' [class.table-success]="detail.reg_checkindatetime !== null">
      <td>{{ detail.reg_details }}</td>
      <td>{{ detail.reg_allocatedseat }}</td>
      <td>
        <button class='btn btn-sm btn-danger mr-1 mb-1' [disabled]='detail.reg_allocatedseat === "---"' (click)='deallocateSeat(detail.reg_id)'><i class="fas fa-chair"></i><i class="fas fa-ban"></i></button>
        <button class='btn btn-sm btn-danger' [disabled]='detail.reg_checkindatetime === null' (click)='undoCheckin(detail.reg_id)'><i class="fas fa-user-clock"></i><i class="fas fa-ban"></i></button>
      </td>
    </tr>
    </tbody>
  </table>
</div>

