import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {MobileMassService} from '@pages/mobile/mobile-start/mobile-mass.service';
import {MassData} from '@/model/mass-data.model';

@Injectable({providedIn: 'root'})
export class MassDataResolver implements Resolve<MassData>{
  constructor(private mobileMassService: MobileMassService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MassData> | Promise<MassData> | MassData {
    if (!this.mobileMassService.massData){
      return this.mobileMassService.fetchMassData(route.parent.params.massid);
    } else{
      return this.mobileMassService.massData;
    }
  }
}
