import {Component, OnDestroy, OnInit} from '@angular/core';
import {MobileMassService} from '@pages/mobile/mobile-start/mobile-mass.service';
import {ApiService} from '@services/api.service';
import {ActivatedRoute, Params} from '@angular/router';
import Swal from 'sweetalert2';
import {Registrant} from '@/model/registrant.model';
import {ZoneData} from '@/model/zone-data.model';
import {RegistrantDbSearched} from '@/model/registrant-db-searched.model';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject} from 'rxjs';

declare var onScan: any;

@Component({
  selector: 'app-desktop-qr',
  templateUrl: './desktop-qr.component.html',
  styleUrls: ['./desktop-qr.component.scss']
})
export class DesktopQrComponent implements OnInit, OnDestroy {
  registrantList: Registrant[] = [];
  zoneList: ZoneData[] = [];
  massid: string;
  showInstructions: boolean;
  isLoading: boolean = false;
  scanLogs: string[] = [];

  searchedRegistrantData: RegistrantDbSearched;
  registrantResultDetails: string;
  registrantResultQrString: string;
  zoneSeatResultQrString: string;
  zoneSeatResultDetails: string;

  constructor(private mobileMassService: MobileMassService,
              private apiService: ApiService,
              private route: ActivatedRoute,
              private toastr: ToastrService) { }

  ngOnDestroy(): void {
    // Remove onScan.js from a DOM element completely
    onScan.detachFrom(document);
    this.clearResult();
  }

  ngOnInit(): void {
    this.mobileMassService.desktopScanLogs.subscribe(resData =>{
      this.scanLogs = resData;
    })
    this.mobileMassService.showDesktopScanInstructions.subscribe(resData =>{
      this.showInstructions = resData;
    });
    this.route.parent.params.subscribe((params: Params) =>{
      this.massid = params.massid;
    })
    this.mobileMassService.registrantsChanged.subscribe(resData =>{
      this.registrantList = resData;
    });
    this.apiService.fetchZoneData(this.massid).subscribe(resData =>{
      this.zoneList = resData;
    });
    let scanResult = new BehaviorSubject<string>('');
    let scanLogs = new BehaviorSubject<string>('Desktop scan initialized');
    // Enable scan events for the entire document
    // Initialize with options
    onScan.attachTo(document, {
      suffixKeyCodes: [13], // enter-key expected at the end of a scan
      reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
      onScan: function(sCode, iQty) { // Alternative to document.addEventListener('scan')
        scanLogs.next('Scanned: ' + iQty + 'x ' + sCode);
        scanResult.next(sCode);
      },
      keyCodeMapper: function(oEvent) {
        // Look for special keycodes or other event properties specific to
        // your scanner
        if (oEvent.which == '189') {
          return '-';
        }
        // Fall back to the default decoder in all other cases
        return onScan.decodeKeyEvent(oEvent);
      }
    });
    scanResult.subscribe(resData =>{
      this.onDetect(resData);
    });
    scanLogs.subscribe(resData =>{
      this.mobileMassService.addDesktopScanLogs(resData);
    })
  }

  skipInstructions(){
    this.mobileMassService.setShowDesktopScanInstructions(false);
  }

  onDetect(resultString: string) {
    if(!this.registrantResultQrString){
      this.registrantResultDetails = null;
      this.searchedRegistrantData = null;
      let foundRegistrant: Registrant;
      foundRegistrant = this.registrantList.find(element => element.reg_id == resultString);
      if(foundRegistrant){
        this.registrantResultQrString = resultString;
        this.registrantResultDetails = foundRegistrant.reg_details;
      }else{
        if(!resultString.includes("COV")){
          this.mobileMassService.addDesktopScanLogs('Database: Lookup initiated');
          this.isLoading = true;
          this.apiService.searchRegistrantData(resultString).subscribe(resData =>{
            this.mobileMassService.addDesktopScanLogs('Database: Registrant Found');
            this.isLoading = false;
            this.searchedRegistrantData = resData;
          }, errorMessage =>{
            this.mobileMassService.addDesktopScanLogs('Database: ' + errorMessage.error.message);
            this.isLoading = false;
            this.registrantResultDetails = errorMessage.error.message;
          })
        }else{
          this.registrantResultDetails = 'Scan Registrant QR first.';
        }
      }
    }else if(!this.zoneSeatResultQrString){
      if(resultString.includes('COV')){
        this.zoneSeatResultQrString = resultString;
        let zoneSeatArray = resultString.split("-");
        let selectedZone: ZoneData = this.zoneList.find(element => element.zone_id === zoneSeatArray[1]);
        this.zoneSeatResultDetails = selectedZone.zone_colour + ' ' + zoneSeatArray[2];
        this.onSubmit(this.registrantResultQrString, zoneSeatArray[1], +zoneSeatArray[2], selectedZone.zone_colour, 'N');
      }else{
        this.zoneSeatResultDetails = resultString + ' is not a valid Zone/Seat QR';
      }

    }

  }

  onSubmit(reg_id: string, zone_id: string, seat: number, zone_colour: string, override: string){
    this.isLoading = true;
    let jsonObject = {
      zone_id: zone_id,
      reg_checkinseat: seat,
      reg_id: reg_id,
      mass_id: this.massid,
      override: override
    }
    this.apiService.checkinRegistrant(jsonObject).subscribe(resData =>{
      this.isLoading = false;
      if(resData.email){
        this.apiService.checkInEmail(reg_id).subscribe();
      }
      this.toastr.success('Successfully checked-in!\n' + this.registrantResultDetails + '->' + zone_colour + ' ' + seat);
      this.mobileMassService.addDesktopScanLogs('Successfully checked-in!\n' + this.registrantResultDetails + '->' + zone_colour + ' ' + seat);
      this.mobileMassService.fetchMassData(this.massid);
      this.mobileMassService.fetchMassRegistrants(this.massid).subscribe();
      this.clearResult();
    }, errorMessage =>{
      this.isLoading = false;
      if(errorMessage.error.error == '406'){
        this.mobileMassService.addDesktopScanLogs('Seat Conflict: ' + errorMessage.error.message);
        Swal.fire({
          title: 'Opps...',
          html: errorMessage.error.message + '<br>Do you want to continue allocating to the same seat?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result) => {
          if(result.isConfirmed){
            this.onSubmit(reg_id, zone_id, seat, zone_colour,'Y');
          }else{
            this.zoneSeatResultDetails = null;
            this.zoneSeatResultQrString = null;
          }
        });
      }else {
        this.mobileMassService.addDesktopScanLogs(errorMessage.error.message);
        Swal.fire({
          title: 'Opps...',
          text: errorMessage.error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {

        });
      }
    })
  }

  clearResult(): void {
    this.registrantResultQrString = null;
    this.registrantResultDetails = null;
    this.zoneSeatResultQrString = null;
    this.zoneSeatResultDetails = null;
  }
}
