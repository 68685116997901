import { Component, OnInit } from '@angular/core';
import {ApiService} from '@services/api.service';
import {NameList} from '@/model/name-list.model';
import {ActivatedRoute, Params} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mobile-full-list',
  templateUrl: './mobile-full-list.component.html',
  styleUrls: ['./mobile-full-list.component.scss']
})
export class MobileFullListComponent implements OnInit {
  searchString = '';
  nameList: NameList[] = [];
  massid: string;
  searchBy: string = 'reg_details';
  isLoading: boolean = false;
  constructor(private apiService: ApiService, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.parent.params.subscribe((params: Params) =>{
      this.massid = params.massid;
      this.apiService.fetchNameList(this.massid).subscribe(resData =>{
        this.isLoading = false;
        this.nameList = resData;
      }, errorMessage =>{
        this.isLoading = false;
      });
    })
  }

  clearSearchString(){
    this.searchString = '';
  }

  deallocateSeat(reg_id: string){
    Swal.fire({
      title: 'Remove the whole check-in record?',
      text: "Deallocate the seat and remove the check-in timestamp. Registrant data will still be kept. It will be as if this registrant never came to Church.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.isLoading = true;
        const jsonBody: Object = {
          "reg_id" : reg_id
        }
        this.apiService.deallocateSeat(jsonBody).subscribe(resData =>{
          this.toastr.success('Check-In data removed!');
          this.apiService.fetchNameList(this.massid).subscribe(resData =>{
            this.isLoading = false;
            this.nameList = resData;
          }, errorMessage =>{
            this.isLoading = false;
          });
        }, errorMessage =>{
          this.isLoading = false;
          Swal.fire({
            title: 'Opps...',
            text: errorMessage.error.message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          });
        })
      }
    })
  }

  undoCheckin(reg_id: string){
    Swal.fire({
      title: 'Remove the check-in record?',
      text: "Will remove only the check-in timestamp but keep the allocated seat intact. Registrant data will still be kept.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.isLoading = true;
        const jsonBody: Object = {
          "reg_id" : reg_id
        }
        this.apiService.undoCheckin(jsonBody).subscribe(resData =>{
          this.toastr.success('Timestamp removed!');
          this.apiService.fetchNameList(this.massid).subscribe(resData =>{
            this.isLoading = false;
            this.nameList = resData;
          }, errorMessage =>{
            this.isLoading = false;
          });
        }, errorMessage =>{
          this.isLoading = false;
          Swal.fire({
            title: 'Opps...',
            text: errorMessage.error.message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          });
        })
      }
    })
  }

}
