import {Component, OnDestroy, OnInit} from '@angular/core';
import {ZoneData} from '@/model/zone-data.model';
import Swal from 'sweetalert2';
import {MobileMassService} from '@pages/mobile/mobile-start/mobile-mass.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ApiService} from '@services/api.service';
import { v4 as uuidv4 } from 'uuid';
import {FormControl, FormGroup} from '@angular/forms';
import {MassData} from '@/model/mass-data.model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-mobile-walkin',
  templateUrl: './mobile-walkin.component.html',
  styleUrls: ['./mobile-walkin.component.scss']
})
export class MobileWalkinComponent implements OnInit, OnDestroy {
  showAllocateSeat:boolean = false;
  zoneList: ZoneData[] = [];
  zoneAllocate: string = null;
  massid: string;
  walkinRegistrationForm: FormGroup;
  massData: MassData;
  massDataSubscription: Subscription;

  constructor(private route: ActivatedRoute, private mobileMassService: MobileMassService, private router: Router, private apiService: ApiService) { }

  ngOnInit(): void {
    this.route.parent.params.subscribe((params: Params) =>{
      this.massid = params.massid;
      this.massDataSubscription = this.mobileMassService.massDataChanged.subscribe(resData =>{
        this.massData = resData;
        if(this.massData.mass_checkedin === this.massData.mass_maxcap){
          Swal.fire({
            title: 'Opps..',
            text: 'Mass\'s maximum capacity reached. Walk-in registration is disabled.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          }).then((result) => {
            this.router.navigate(['../'], {relativeTo: this.route});
          });
        }
      });
    })
    this.initForm();
  }

  async allocateSeat(zone_id: string){
    let selectedZone: ZoneData = this.zoneList.find(element => element.zone_id === zone_id);
    const { value: seat } = await Swal.fire({
      title: 'Input seat number',
      input: 'number',
      inputLabel: 'Selected Zone: ' + selectedZone.zone_colour,
      inputPlaceholder: 'Enter seat number',
      showCancelButton: false,
      confirmButtonText: 'Submit',
      inputValidator: (value) => {
        if (!value) {
          return 'You need to input something!'
        }else if(+value < +selectedZone.zone_nostart || +value > +selectedZone.zone_noend){
          return 'Seat number not in range ' + selectedZone.zone_nostart + '-' + selectedZone.zone_noend;
        }
      }
    })

    if (seat) {
      this.onSubmit(selectedZone, zone_id, seat, 'N');
    }else{
      this.zoneAllocate = null;
    }
  }

  onSubmit(selectedZone: ZoneData, zone_id: string, seat: number, override: string){
    let reg_age = this.walkinRegistrationForm.value.reg_age;
    let today = new Date();
    let currentYear = today.getFullYear();
    let reg_birthdate = (currentYear - reg_age) +'-01-' + '01';
    let jsonObject = {
      zone_id: zone_id,
      reg_checkinseat: seat,
      reg_id: uuidv4(),
      mass_id: this.massid,
      reg_name: this.walkinRegistrationForm.value.reg_name,
      reg_birthdate: reg_birthdate,
      reg_phone: this.walkinRegistrationForm.value.reg_phone,
      override: override
    }
    this.apiService.registerWalkin(jsonObject).subscribe(resData =>{
      Swal.fire({
        title: 'Good job!',
        text: this.walkinRegistrationForm.value.reg_name.toUpperCase() + ' checked-in & allocated to seat ' + selectedZone.zone_colour + ' ' + seat,
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      }).then((result) => {
        this.mobileMassService.fetchMassData(this.massid).subscribe();
        this.router.navigate(['../'], {relativeTo: this.route});
      });
    }, errorMessage =>{
      if(errorMessage.error.error == '406'){
        Swal.fire({
          title: 'Opps...',
          html: errorMessage.error.message + '<br>Do you want to continue allocating to the same seat?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if(result.isConfirmed){
            this.onSubmit(selectedZone, zone_id, seat, 'Y');
          }else{
            this.zoneAllocate = null;
          }
        });
      }else {
        Swal.fire({
          title: 'Opps...',
          text: errorMessage.error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.mobileMassService.fetchMassData(this.massid).subscribe();
          this.router.navigate(['../'], {relativeTo: this.route});
        });
      }
    })
  }

  navigateToList(){
    Swal.fire({
      title: 'Leave without submitting?',
      text: "You will be navigated back to the name list!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['../'], {relativeTo: this.route});
      }
    })
  }

  private initForm(){
    let reg_name = '';
    let reg_age = '';
    let reg_phone = '';
    this.walkinRegistrationForm = new FormGroup({
      reg_name: new FormControl(reg_name),
      reg_age: new FormControl(reg_age),
      reg_phone: new FormControl(reg_phone),
    })
  }

  sweetAlert(title: string, text: string){
    Swal.fire({
      title: title,
      html: text,
      icon: 'error',
      imageHeight: 200,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    });
  }

  navigateToAllocateSeat(){
    let reg_name = this.walkinRegistrationForm.value.reg_name;
    let reg_age = this.walkinRegistrationForm.value.reg_age;
    let reg_phone = this.walkinRegistrationForm.value.reg_phone;
    if(!reg_name){
      this.sweetAlert('Opps...', 'Name cannot be empty.');
      return;
    }
    if(/\d/g.test(reg_name)){
      this.sweetAlert('Opps...', 'Name cannot contain numbers.<br><strong>Reminder:</strong><br> Please enter Name (Not NRIC Number)');
      return;
    }
    if(reg_age < this.massData.mass_minage || reg_age > this.massData.mass_maxage){
      this.sweetAlert('Opps...', 'Age should be between ' + this.massData.mass_minage + ' and ' + this.massData.mass_maxage + '.');
      return;
    }
    if(!reg_phone){
      this.sweetAlert('Opps...', 'Phone number cannot be left empty.');
      return;
    }
    this.showAllocateSeat = true;
    this.apiService.fetchZoneData(this.massid).subscribe(resData =>{
      this.zoneList = resData;
    })
  }

  navigateToWalkInForm(){
    this.showAllocateSeat = false;
    this.zoneAllocate = null;
  }

  ngOnDestroy(): void {
    this.massDataSubscription.unsubscribe();
  }

}
