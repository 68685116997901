import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MobileMassService} from '@pages/mobile/mobile-start/mobile-mass.service';
import {Registrant} from '@/model/registrant.model';
import Swal from 'sweetalert2';
import {Subscription} from 'rxjs';
import {ZoneData} from '@/model/zone-data.model';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-mobile-checkin',
  templateUrl: './mobile-checkin.component.html',
  styleUrls: ['./mobile-checkin.component.scss']
})
export class MobileCheckinComponent implements OnInit, OnDestroy {
  massid: string;
  regid: string;
  selectedRegistrant: Registrant;
  registrantListSubscription: Subscription;
  zoneList: ZoneData[] = [];
  zoneAllocate: string = null;
  routeSubscription: Subscription;
  isLoading: boolean = false;

  constructor(private route: ActivatedRoute,
              private mobileMassService: MobileMassService,
              private router: Router,
              private apiService: ApiService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.route.parent.params.subscribe((params: Params) =>{
      this.massid = params.massid;
    })
    this.routeSubscription = this.route.params.subscribe(
      (params: Params) =>{
        this.regid = params.regid;
        this.selectedRegistrant = this.mobileMassService.getRegistrant(this.regid);
        if(!this.selectedRegistrant){
          Swal.fire({
            title: 'Opps...',
            text: 'Registrant ID is not found in array.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          }).then((result) => {
            this.router.navigate(['../'], {relativeTo: this.route});
          });
        }else{
          this.isLoading = true;
          this.apiService.fetchZoneData(this.massid).subscribe(resData =>{
            this.isLoading = false;
            this.zoneList = resData;
          }, errorMessage => {
            this.isLoading = false;
          })
        }
      })
  }


  async allocateSeat(zone_id: string){
    let selectedZone: ZoneData = this.zoneList.find(element => element.zone_id === zone_id);
    const { value: seat } = await Swal.fire({
      title: 'Input seat number',
      input: 'number',
      inputLabel: 'Selected Zone: ' + selectedZone.zone_colour,
      inputPlaceholder: 'Enter seat number',
      showCancelButton: false,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to input something!'
        }else if(+value < +selectedZone.zone_nostart || +value > +selectedZone.zone_noend){
          return 'Seat number not in range ' + selectedZone.zone_nostart + '-' + selectedZone.zone_noend;
        }
      }
    })

    if (seat) {
      this.onSubmit(zone_id, seat, 'N');
    }else{
      this.zoneAllocate = null;
    }
  }

  onSubmit(zone_id: string, seat: number, override: string){
    this.isLoading = true;
    let jsonObject = {
      zone_id: zone_id,
      reg_checkinseat: seat,
      reg_id: this.regid,
      mass_id: this.massid,
      override: override
    }
    this.apiService.checkinRegistrant(jsonObject).subscribe(resData =>{
      if(resData.email){
        this.apiService.checkInEmail(this.regid).subscribe();
      }
      this.isLoading = false;
      this.toastr.success('Successfully checked-in!');
      this.mobileMassService.fetchMassData(this.massid);
      this.router.navigate(['../'], {relativeTo: this.route});
    }, errorMessage =>{
      this.isLoading = false;
      if(errorMessage.error.error == '406'){
        Swal.fire({
          title: 'Opps...',
          html: errorMessage.error.message + '<br>Do you want to continue allocating to the same seat?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if(result.isConfirmed){
            this.onSubmit(zone_id, seat, 'Y');
          }else{
            this.zoneAllocate = null;
          }
        });
      }else {
        Swal.fire({
          title: 'Opps...',
          text: errorMessage.error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.mobileMassService.fetchMassData(this.massid);
          this.mobileMassService.fetchMassRegistrants(this.massid);
          this.router.navigate(['../'], {relativeTo: this.route});
        });
      }
    })
  }

  navigateToList(){
    Swal.fire({
      title: 'Leave without seat allocation?',
      text: "You will be navigated back to the name list!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['../'], {relativeTo: this.route});
      }
    })
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }
}
