import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {PrivacyPolicyComponent} from '@modules/privacy-policy/privacy-policy.component';
import {MobileComponent} from '@pages/mobile/mobile.component';
import {MobileStartComponent} from '@pages/mobile/mobile-start/mobile-start.component';
import {MobileListComponent} from '@pages/mobile/mobile-start/mobile-list/mobile-list.component';
import {MobileWalkinComponent} from '@pages/mobile/mobile-start/mobile-walkin/mobile-walkin.component';
import {MobileQrComponent} from '@pages/mobile/mobile-start/mobile-qr/mobile-qr.component';
import {MobileMassComponent} from '@pages/mobile/mobile-mass/mobile-mass.component';
import {RegistrantListResolver} from '@pages/mobile/mobile-start/registrant-list.resolver';
import {MobileCheckinComponent} from '@pages/mobile/mobile-start/mobile-checkin/mobile-checkin.component';
import {MobileFullListComponent} from '@pages/mobile/mobile-start/mobile-full-list/mobile-full-list.component';
import {MassDataResolver} from '@pages/mobile/mass-data.resolver';
import {DesktopQrComponent} from '@pages/desktop/desktop-qr/desktop-qr.component';

const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'desktop', component: MainComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], children:
      [
        {path: 'dashboard', component: DashboardComponent, pathMatch: 'full'},
        {path: 'profile', component: ProfileComponent},
        {path: 'blank', component: BlankComponent}
      ]
  },
  {path: 'mobile', component: MobileComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
      {path: '', component: MobileMassComponent, pathMatch: 'full'},
      {path: ':massid', component: MobileStartComponent, children: [
          {path: '', component: MobileListComponent, pathMatch: 'full'},
          {path: 'walk-in', component: MobileWalkinComponent, resolve: [MassDataResolver]},
          {path: 'qr', component: MobileQrComponent, resolve: [RegistrantListResolver]},
          {path: 'desktop-qr', component: DesktopQrComponent, resolve: [RegistrantListResolver]},
          {path: 'full', component: MobileFullListComponent},
          {path: ':regid', component: MobileCheckinComponent, resolve: [RegistrantListResolver]}
        ]}
    ]},
  {path: 'login', component: LoginComponent, canActivate: [NonAuthGuard]},
  {path: 'privacy-policy', component: PrivacyPolicyComponent, canActivate: [NonAuthGuard]},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
